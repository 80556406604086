



























import { Component, Vue, Watch } from 'vue-property-decorator';
import { PropsPatch } from '../helper/Props';

@Component
export default class GridsMenu extends Vue{
  private props: PropsPatch = {
    grid: {
      visible: true,
      snap: true,
      size: { x: 12, y: 12, validated: true }
    }
  };
  private formValidate: boolean = true;

  mounted() {
    // CanvasAreaのcanvas読み込みを待つためnextTickで反映する
    this.$nextTick(() => {
      this.onPropsChanged();
    });
  }

  // ---- Computed ----
  get gridSizeRules(): Array<(value: string | number) => boolean | string> {
    return [
      v => typeof v == 'number' ||
        '数値を入力してください',
      v => v >= 1 && v <= 32 ||
        '1~32の範囲で入力してください'
    ];
  }

  // ---- Watchers ----

  @Watch('props', { deep: true })
  onPropsChanged() {
    // nextTick3個 = 3tick後
    // v-formのValidationの更新に3フレームかかるらしく、こうしないと値が正確に取れない
    // 解決法がわかったら修正
    this.$nextTick(() => { this.$nextTick(() => { this.$nextTick(() => {
      this.$emit('propertiesChanged', this.props);
    });});});
  }

  @Watch('props.grid.visible')
  onVisibleChanged(value: boolean) {
    if (this.props.grid && !value) {
      this.props.grid.snap = false;
    }
  }

  @Watch('props.grid.snap')
  onSnapChanged(value: boolean) {
    if (this.props.grid && value) {
      this.props.grid.visible = true;
    }
  }
}
