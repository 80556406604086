
































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { PropsPatch } from '../helper/Props';

@Component
export default class PagePropertiesMenu extends Vue{
  private properties: PropsPatch = {
    lineWidth: 5,
    thickness: {
      horizontal: 20,
      vertical: 10,
    },
    frame: {
      width: 600,
      height: 800,
    },
    canvas: {
      width: 840,
      height: 1188,
    },
  }
  private canvasFormValidate: boolean = true;
  private frameFormValidate: boolean = true;

  mounted() {
    // CanvasAreaのcanvas読み込みを待つためnextTickで反映する
    this.$nextTick(() => {
      this.onPropertiesChanged();
    });
  }

  // ---- Computed ----
  get widthHeightRules(): Array<(value: string | number) => boolean | string> {
    return [
      v => typeof v == 'number' ||
        '数値を入力してください',
      v => v >= 400 && v <= 2400 ||
        '400~2400の範囲で入力してください'
    ];
  }

  get propertiesValidated(): PropsPatch {
    // コピーを作成
    let props = { ... this.properties };

    // バリデーションに通らなかったパラメータを消していく
    if (!this.canvasFormValidate) {
      delete props.canvas;
    }
    if (!this.frameFormValidate) {
      delete props.frame;
    }

    return props;
  }

  // ---- Watchers ----

  @Watch('properties', { deep: true })
  onPropertiesChanged() {
    // nextTick3個 = 3tick後
    // v-formのValidationの更新に3フレームかかるらしく、こうしないと値が正確に取れない
    // 解決法がわかったら修正
    this.$nextTick(() => { this.$nextTick(() => { this.$nextTick(() => {
      this.$emit('propertiesChanged', this.propertiesValidated);
    });});});
  }
}
